import {
  FETCH_AREA_OPTIONS,
  FETCH_AREA_VALUE,
  FETCH_CITY_COMPARISON_DATA,
  FETCH_CITY_OPTIONS,
  FETCH_CITY_VALUE,
  FETCH_COMPARISON_GROUP_VALUE,
  FETCH_DASHBOARD_DATA,
  FETCH_INDICATOR_OPTIONS,
  FETCH_INDICATOR_VALUE,
  FETCH_SUBAREA_OPTIONS,
  FETCH_SUBAREA_VALUE,
  UPDATE_AREA_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_COMPARISON_GROUP_FILTER,
  UPDATE_INDICATOR_FILTER,
  UPDATE_SUBAREA_FILTER,
} from '../actions/actionTypes';
import { LANDING_PAGE } from '../../configuration/paths';
import { success } from '../actions/makeAction';

const DEFAULT_STATE = {
  cityFilter: { options: [] },
  areaFilter: { options: [] },
  subAreaFilter: { options: [] },
  indicatorFilter: { options: [] },
  comparisonGroupFilter: { options: [] },
  isChanged: false,
};

const filters = (state, { type, payload }) => {
  if (!state) {
    return DEFAULT_STATE;
  }
  switch (type) {
    case UPDATE_CITY_FILTER:
      return {
        ...state,
        cityFilter: {
          ...state.cityFilter,
          value: payload,
        },
        isChanged: true,
      };
    case success(FETCH_CITY_VALUE):
      return {
        ...state,
        cityFilter: {
          ...state.cityFilter,
          value: payload,
        },
      };
    case UPDATE_AREA_FILTER:
      return {
        ...state,
        areaFilter: {
          ...state.areaFilter,
          value: payload,
        },
        subAreaFilter: {
          options: [],
        },
        indicatorFilter: {
          options: [],
        },
        isChanged: true,
      };
    case success(FETCH_AREA_VALUE):
      return {
        ...state,
        areaFilter: {
          ...state.areaFilter,
          value: payload,
        },
      };
    case UPDATE_SUBAREA_FILTER:
      return {
        ...state,
        subAreaFilter: {
          ...state.subAreaFilter,
          value: payload,
        },
        indicatorFilter: {
          options: [],
        },
        isChanged: true,
      };
    case success(FETCH_SUBAREA_VALUE):
      return {
        ...state,
        subAreaFilter: {
          ...state.subAreaFilter,
          value: payload,
        },
      };
    case UPDATE_INDICATOR_FILTER:
      return {
        ...state,
        indicatorFilter: {
          ...state.indicatorFilter,
          value: payload,
        },
        isChanged: true,
      };
    case success(FETCH_INDICATOR_VALUE):
      return {
        ...state,
        indicatorFilter: {
          ...state.indicatorFilter,
          value: payload,
        },
      };
    case UPDATE_COMPARISON_GROUP_FILTER:
      return {
        ...state,
        comparisonGroupFilter: {
          ...state.comparisonGroupFilter,
          value: payload,
        },
        isChanged: true,
      };
    case success(FETCH_COMPARISON_GROUP_VALUE):
      return {
        ...state,
        comparisonGroupFilter: {
          ...state.comparisonGroupFilter,
          value: payload,
        },
      };
    case success(FETCH_CITY_OPTIONS):
      return {
        ...state,
        cityFilter: {
          ...state.cityFilter,
          options: payload,
        },
        comparisonGroupFilter: {
          ...state.comparisonGroupFilter,
          options: payload,
        },
      };
    case success(FETCH_AREA_OPTIONS):
      return {
        ...state,
        areaFilter: {
          ...state.areaFilter,
          options: payload,
        },
      };
    case success(FETCH_SUBAREA_OPTIONS):
      return {
        ...state,
        subAreaFilter: {
          ...state.subAreaFilter,
          options: payload,
        },
      };
    case success(FETCH_INDICATOR_OPTIONS):
      return {
        ...state,
        indicatorFilter: {
          ...state.indicatorFilter,
          options: payload,
        },
      };
    case FETCH_DASHBOARD_DATA:
    case FETCH_CITY_COMPARISON_DATA:
      return {
        ...state,
        isChanged: false,
      };
    case '@@router/LOCATION_CHANGE': {
      if (payload.location.pathname === LANDING_PAGE) {
        return {
          ...state,
          cityFilter: {
            options: state.cityFilter.options,
          },
          areaFilter: {
            options: state.areaFilter.options,
          },
          subAreaFilter: {
            options: state.subAreaFilter.options,
          },
          indicatorFilter: {
            options: state.indicatorFilter.options,
          },
          comparisonGroupFilter: {
            options: state.comparisonGroupFilter.options,
          },
          isChanged: false,
        };
      }
      return {
        ...state,
        isChanged: false,
      };
    }
    default:
      return state;
  }
};

export default filters;
