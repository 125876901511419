import React from 'react';
import { connect } from 'react-redux';
import { Col, Menu, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CITY_COMPARISON_PAGE, DASHBOARD_PAGE, LANDING_PAGE } from '../configuration/paths';
import { makeAction } from '../redux/actions/makeAction';
import { UPDATE_CITY_FILTER } from '../redux/actions/actionTypes';
import { getCityComparisonURL } from '../redux/selectors/cityComparison';
import { getDashboardURL } from '../redux/selectors/dashboard';
import SelectCity from './pages/city_comparison/SelectCity';

function Header({ cityComparisonURL, dashboardURL, updateCityFilter }) {
  const { pathname } = useLocation();

  return (
    <header>
      <div className="container">
        <Row gutter={[20, 0]}>
          <Col span={4}>
            <Link to={LANDING_PAGE}>
              <span className="header-logo">Monitor Rozwoju Lokalnego</span>
            </Link>
          </Col>
          <Col span={4} className="chosen-city">
            <SelectCity
              filterName="cityFilter"
              selectProps={{
                placeholder: 'Miasto',
                onChange: updateCityFilter,
                labelInValue: true,
                showArrow: true,
                showSearch: true,
              }}
              cityFilter
            />
          </Col>
          <Col span={10}>
            <Menu selectedKeys={pathname} mode="horizontal">
              <Menu.Item key={DASHBOARD_PAGE}>
                <Link to={dashboardURL}>
                  <span className="nav-item">Wskaźniki</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={CITY_COMPARISON_PAGE}>
                <Link to={cityComparisonURL}>
                  <span className="nav-item">Grupy porównawcze</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={6} className="partners" />
        </Row>
      </div>
    </header>
  );
}

Header.propTypes = {
  cityComparisonURL: PropTypes.string.isRequired,
  dashboardURL: PropTypes.string.isRequired,
  updateCityFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cityComparisonURL: getCityComparisonURL(state),
  dashboardURL: getDashboardURL(state),
});

const mapDispatchToProps = {
  updateCityFilter: makeAction(UPDATE_CITY_FILTER),
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
