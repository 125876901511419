import React, { useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { makeAction } from '../../../redux/actions/makeAction';
import {
  FETCH_AREA_OPTIONS,
  FETCH_INDICATOR_OPTIONS,
  FETCH_SUBAREA_OPTIONS,
  UPDATE_AREA_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_COMPARISON_GROUP_FILTER,
  UPDATE_INDICATOR_FILTER,
  UPDATE_SUBAREA_FILTER,
} from '../../../redux/actions/actionTypes';
import {
  getAreaId,
  getCityId,
  getComparisonGroupIds,
  getIndicatorId,
  getSubAreaId,
  isCityComparisonFilterComplete,
} from '../../../redux/selectors/filters';
import { getCityComparisonURL } from '../../../redux/selectors/cityComparison';
import FilterSelect from '../../FilterSelect';
import SelectCity from './SelectCity';

function CityComparisonFilterPanel({
  fetchAreaOptions,
  fetchSubAreaOptions,
  fetchIndicatorOptions,
  updateAreaFilter,
  updateIndicatorFilter,
  updateComparisonGroupFilter,
  updateSubAreaFilter,
  areaId,
  subAreaId,
  buttonDisabled,
  cityComparisonURL,
}) {
  const location = useLocation();
  useEffect(() => {
    fetchAreaOptions();
    if (areaId) {
      fetchSubAreaOptions(areaId);
      if (subAreaId) {
        fetchIndicatorOptions(subAreaId);
      }
    }
  }, [location]);

  function handleAreaChange(area) {
    updateAreaFilter(area);
    fetchSubAreaOptions(area.key);
  }

  function handleSubAreaChange(subArea) {
    updateSubAreaFilter(subArea);
    fetchIndicatorOptions(subArea.key);
  }

  return (
    <div className="filter-panel">
      <div className="container">
        <div className="filter-wrapper">
          <Row gutter={[20, 0]}>
            <Col span={4}>
              <span>Obszar</span>
            </Col>
            <Col span={4}>
              <span>Podobszar</span>
            </Col>
            <Col span={4}>
              <span>Wskaźnik</span>
            </Col>
            <Col span={8}>
              <span>Grupa porównawcza</span>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col span={4}>
              <FilterSelect
                filterName="areaFilter"
                selectProps={{ placeholder: 'Obszar', onChange: handleAreaChange }}
              />
            </Col>
            <Col span={4}>
              <FilterSelect
                filterName="subAreaFilter"
                selectProps={{
                  placeholder: 'Podobszar',
                  onChange: handleSubAreaChange,
                  disabled: !areaId,
                }}
              />
            </Col>
            <Col span={4}>
              <FilterSelect
                filterName="indicatorFilter"
                selectProps={{
                  placeholder: 'Wskaźnik',
                  onChange: updateIndicatorFilter,
                  disabled: !subAreaId,
                }}
              />
            </Col>
            <Col span={8}>
              <SelectCity
                filterName="comparisonGroupFilter"
                selectProps={{
                  placeholder: 'Grupa porównawcza',
                  mode: 'multiple',
                  maxTagCount: 3,
                  maxTagTextLength: 10,
                  onChange: updateComparisonGroupFilter,
                  labelInValue: true,
                }}
              />
            </Col>
            <Col span={4}>
              <Link to={cityComparisonURL}>
                <Button type="primary" disabled={buttonDisabled} block>
                  Zastosuj
                  <RightOutlined />
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

CityComparisonFilterPanel.defaultProps = {
  areaId: undefined,
  subAreaId: undefined,
};

CityComparisonFilterPanel.propTypes = {
  updateAreaFilter: PropTypes.func.isRequired,
  updateSubAreaFilter: PropTypes.func.isRequired,
  updateIndicatorFilter: PropTypes.func.isRequired,
  updateComparisonGroupFilter: PropTypes.func.isRequired,
  areaId: PropTypes.number,
  subAreaId: PropTypes.number,
  fetchAreaOptions: PropTypes.func.isRequired,
  fetchSubAreaOptions: PropTypes.func.isRequired,
  fetchIndicatorOptions: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  cityComparisonURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  indicatorId: getIndicatorId(state),
  comparisonGroupIds: getComparisonGroupIds(state),
  areaId: getAreaId(state),
  cityId: getCityId(state),
  subAreaId: getSubAreaId(state),
  buttonDisabled: !isCityComparisonFilterComplete(state),
  cityComparisonURL: getCityComparisonURL(state),
});

const mapDispatchToProps = {
  updateCityFilter: makeAction(UPDATE_CITY_FILTER),
  updateAreaFilter: makeAction(UPDATE_AREA_FILTER),
  updateSubAreaFilter: makeAction(UPDATE_SUBAREA_FILTER),
  updateIndicatorFilter: makeAction(UPDATE_INDICATOR_FILTER),
  updateComparisonGroupFilter: makeAction(UPDATE_COMPARISON_GROUP_FILTER),
  fetchAreaOptions: makeAction(FETCH_AREA_OPTIONS),
  fetchSubAreaOptions: makeAction(FETCH_SUBAREA_OPTIONS),
  fetchIndicatorOptions: makeAction(FETCH_INDICATOR_OPTIONS),
};

export default connect(mapStateToProps, mapDispatchToProps)(CityComparisonFilterPanel);
