import { all, put, takeLatest } from 'redux-saga/effects';
import request, { GET } from '../../configuration/request';
import { API_URL } from '../../configuration/paths';
import {
  FETCH_AREA_OPTIONS,
  FETCH_AREA_VALUE,
  FETCH_CITY_OPTIONS,
  FETCH_CITY_VALUE,
  FETCH_COMPARISON_GROUP_VALUE,
  FETCH_INDICATOR_OPTIONS,
  FETCH_INDICATOR_VALUE,
  FETCH_SUBAREA_OPTIONS,
  FETCH_SUBAREA_VALUE,
} from '../actions/actionTypes';
import { showServerError } from '../../services/notifications';
import { failed, success } from '../actions/makeAction';

function* fetchCityOptions({ type, payload }) {
  try {
    const response = yield request(GET, `${API_URL}/gmina/`, {}, { params: { name: payload } });
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchCityValue({ type, payload }) {
  try {
    const response = yield request(GET, `${API_URL}/gmina/${payload}/`);
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchAreaOptions({ type }) {
  try {
    const response = yield request(GET, `${API_URL}/indicator_area/`);
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchAreaValue({ type, payload }) {
  try {
    const response = yield request(GET, `${API_URL}/indicator_area/${payload}/`);
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchSubAreaOptions({ type, payload }) {
  try {
    const response = yield request(
      GET,
      `${API_URL}/indicator_subarea/`,
      {},
      { params: { area: payload } },
    );
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}
function* fetchSubAreaValue({ type, payload }) {
  try {
    const response = yield request(GET, `${API_URL}/indicator_subarea/${payload}/`);
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchIndicatorOptions({ type, payload }) {
  try {
    const response = yield request(
      GET,
      `${API_URL}/indicator/`,
      {},
      { params: { subarea: payload } },
    );
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchIndicatorValue({ type, payload }) {
  try {
    const response = yield request(GET, `${API_URL}/indicator/${payload}/`);
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

function* fetchComparisonGroupValue({ type, payload }) {
  try {
    const response = yield request(
      GET,
      `${API_URL}/gmina/`,
      {},
      { params: { comparison_group: payload } },
    );
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    yield showServerError();
  }
}

export default function* filters() {
  yield all([
    yield takeLatest(FETCH_CITY_OPTIONS, fetchCityOptions),
    yield takeLatest(FETCH_CITY_VALUE, fetchCityValue),
    yield takeLatest(FETCH_AREA_OPTIONS, fetchAreaOptions),
    yield takeLatest(FETCH_AREA_VALUE, fetchAreaValue),
    yield takeLatest(FETCH_SUBAREA_OPTIONS, fetchSubAreaOptions),
    yield takeLatest(FETCH_SUBAREA_VALUE, fetchSubAreaValue),
    yield takeLatest(FETCH_INDICATOR_OPTIONS, fetchIndicatorOptions),
    yield takeLatest(FETCH_INDICATOR_VALUE, fetchIndicatorValue),
    yield takeLatest(FETCH_COMPARISON_GROUP_VALUE, fetchComparisonGroupValue),
  ]);
}
