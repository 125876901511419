import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LineChartOutlined } from '@ant-design/icons';
import { makeAction } from '../../../redux/actions/makeAction';
import { TOGGLE_CITY_COMPARISON_ROW_SELECT } from '../../../redux/actions/actionTypes';

function ChartSeriesToggle({ seriesKey, isSelected, toggleSelection }) {
  const handleClick = () => toggleSelection(seriesKey);
  return (
    <LineChartOutlined
      style={{ color: isSelected ? '#0ABA9D' : '#DCDCDC' }}
      onClick={handleClick}
    />
  );
}

ChartSeriesToggle.propTypes = {
  seriesKey: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleSelection: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  toggleSelection: makeAction(TOGGLE_CITY_COMPARISON_ROW_SELECT),
};

export default connect(null, mapDispatchToProps)(ChartSeriesToggle);
