import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import './styles/index.less';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import rootSaga from './redux/saga';
import rootReducer from './redux/reducers';
import App from './App';
import Router from './configuration/routes';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line import/prefer-default-export
export const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
);

sagaMiddleware.run(rootSaga);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <App>
          <Router />
        </App>
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);
