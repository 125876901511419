import { all, put, takeLatest } from 'redux-saga/effects';
import request, { GET } from '../../configuration/request';
import { API_URL } from '../../configuration/paths';
import { FETCH_DASHBOARD_DATA } from '../actions/actionTypes';
import { showServerError } from '../../services/notifications';
import { failed, success } from '../actions/makeAction';

function* fetchDashboardData({ type, payload }) {
  try {
    const { gmina, subarea } = payload;
    const response = yield request(
      GET,
      `${API_URL}/dashboard/`,
      {},
      { params: { gmina, subarea } },
    );
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    if (error.response.status >= 500) {
      yield showServerError();
    }
  }
}

export default function* dashboard() {
  yield all([yield takeLatest(FETCH_DASHBOARD_DATA, fetchDashboardData)]);
}
