import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Drawer, Switch, Tooltip } from 'antd';
import {
  BarsOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';
import { API_URL } from '../configuration/paths';

function downloadTableImage() {
  const table = document.getElementById('table');
  htmlToImage.toPng(table).then((dataUrl) => {
    const link = document.createElement('a');
    link.download = 'table.png';
    link.href = dataUrl;
    link.click();
  });
}

function TableSettings({
  functionColumns,
  updateTableColumns,
  functionRows,
  updateTableRows,
  showStructure,
  updateStructure,
  hasStructure,
  exportURL,
}) {
  const [drawerVisible, setDrawerVisible] = useState(false);

  function handleDrawerToggle() {
    setDrawerVisible(!drawerVisible);
  }

  const query = new URLSearchParams(exportURL);
  const indicatorId = query.get('indicator');

  const location = useLocation();

  return (
    <div className="table-settings">
      <Button size="small" onClick={handleDrawerToggle} icon={<BarsOutlined />}>
        Dodatkowe funkcje
      </Button>
      <Button size="small" onClick={downloadTableImage} icon={<DownloadOutlined />}>
        Pobierz PNG
      </Button>
      <Button size="small" href={exportURL} icon={<DownloadOutlined />}>
        Pobierz XLSX
      </Button>
      {location.pathname.includes('city_comparison') && (
        <Button
          size="small"
          href={`${API_URL}/indicator_export/?key=${indicatorId}`}
          target="_blank"
          icon={<SearchOutlined />}
        >
          Szczególy wskażnika (PDF)
        </Button>
      )}
      <Drawer
        title="Dodatkowe funkcje"
        placement="left"
        visible={drawerVisible}
        closable
        onClose={handleDrawerToggle}
      >
        <div className="function-columns">
          <div className="title">
            <span>Dodatkowa kolumna</span>
          </div>
          <div className="toggle">
            <span>Różnica</span>
            <Switch
              checked={functionColumns.difference}
              onChange={(value) => updateTableColumns({ column: 'difference', value })}
            />
          </div>
          <div className="toggle">
            <span>Dynamika</span>
            <Tooltip
              title="(x(n)/x(n-t)-1)*100 (gdzie t jest to pierwszy rok z danymi)"
              placement="top"
            >
              <InfoCircleOutlined />
            </Tooltip>
            <Switch
              checked={functionColumns.dynamic}
              onChange={(value) => updateTableColumns({ column: 'dynamic', value })}
            />
          </div>
          <div className="toggle">
            <span>Średnia</span>
            <Switch
              checked={functionColumns.mean}
              onChange={(value) => updateTableColumns({ column: 'mean', value })}
            />
          </div>
          <div className="toggle">
            <span>Suma</span>
            <Switch
              checked={functionColumns.sum}
              onChange={(value) => updateTableColumns({ column: 'sum', value })}
            />
          </div>
        </div>
        <div className="function-rows">
          <div className="title">
            <span>Dodatkowy wiersz</span>
          </div>
          <div className="toggle">
            <span>Średnia</span>
            <Switch
              checked={functionRows.mean}
              onChange={(value) => updateTableRows({ row: 'mean', value })}
            />
          </div>
          <div className="toggle">
            <span>Suma</span>
            <Switch
              checked={functionRows.sum}
              onChange={(value) => updateTableRows({ row: 'sum', value })}
            />
          </div>
        </div>
        {hasStructure && (
          <div className="structure">
            <div className="title">
              <span>Struktura</span>
            </div>
            <div className="toggle">
              <span>Widok struktury</span>
              <Switch checked={showStructure} onChange={updateStructure} />
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}

TableSettings.defaultProps = {
  hasStructure: false,
};

TableSettings.propTypes = {
  functionColumns: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    mean: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableColumns: PropTypes.func.isRequired,
  functionRows: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    mean: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableRows: PropTypes.func.isRequired,
  showStructure: PropTypes.bool.isRequired,
  updateStructure: PropTypes.func.isRequired,
  hasStructure: PropTypes.bool,
  exportURL: PropTypes.string.isRequired,
};

export default TableSettings;
