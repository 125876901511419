import { all, put, takeLatest } from 'redux-saga/effects';
import request, { GET } from '../../configuration/request';
import { API_URL } from '../../configuration/paths';
import { FETCH_CITY_COMPARISON_DATA } from '../actions/actionTypes';
import { showServerError } from '../../services/notifications';
import { failed, success } from '../actions/makeAction';

function* fetchCityComparisonData({ type, payload }) {
  try {
    const { indicator, comparison_group: comparisonGroup, reference } = payload;
    const response = yield request(
      GET,
      `${API_URL}/city_comparison/`,
      {},
      { params: { indicator, comparison_group: comparisonGroup, reference } },
    );
    yield put({ type: success(type), payload: response.data });
  } catch (error) {
    yield put({ type: failed(type), payload: error });
    if (error.response.status >= 500) {
      yield showServerError();
    }
  }
}

export default function* cityComparison() {
  yield all([yield takeLatest(FETCH_CITY_COMPARISON_DATA, fetchCityComparisonData)]);
}
