import request from 'axios';
import { call } from 'redux-saga/effects';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const HEAD = 'HEAD';
export const PATCH = 'PATCH';

const service = (requestType, url, data = {}, config = {}) => {
  const reqConfig = {
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
    ...config,
    headers: {
      Accept: 'application/json',
      ...config.headers,
    },
  };

  switch (requestType) {
    case GET: {
      return call(request.get, url, reqConfig);
    }
    case POST: {
      return call(request.post, url, data, reqConfig);
    }
    case PUT: {
      return call(request.put, url, data, reqConfig);
    }
    case DELETE: {
      return call(request.delete, url, reqConfig);
    }
    case HEAD: {
      return call(request.head, url, reqConfig);
    }
    case PATCH: {
      return call(request.patch, url, data, reqConfig);
    }
    default: {
      throw new TypeError('No valid request type provided');
    }
  }
};

export default service;
