import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Switch } from 'antd';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import NoData from './NoData';

function ChartContainer({ chartData, isLoading, showDynamic, toggleDynamic }) {
  return (
    <div className="chart-container">
      <div className="chart-title">
        <h2>{showDynamic ? 'Dynamika' : 'Wartość'}</h2>
        <div>
          <span>Dynamika: </span>
          <Switch checked={showDynamic} onChange={toggleDynamic} />
        </div>
      </div>
      <div className="chart-body">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : _.isEmpty(chartData.data) ? (
          <NoData />
        ) : (
          <Plot
            data={chartData.data.map((series) => ({
              ...series,
              y: showDynamic ? series.dynamic_values : series.values,
            }))}
            className="plotly-chart"
            layout={{
              autosize: true,
              showlegend: true,
              title: {
                text: chartData.title,
                xref: 'paper',
                y: 0.985,
              },
              legend: {
                itemclick: false,
                itemdoubleclick: false,
                orientation: 'h',
              },
              margin: {
                t: 25,
                b: 15,
                l: 30,
                r: 25,
                autoexpand: true,
              },
              xaxis: {
                type: 'linear',
                dtick: 1,
              },
              yaxis: {
                automargin: true,
                exponentformat: 'none',
                separatethousands: true,
              },
              separators: ', ',
              ticks: 'outside',
              hovermode: 'closest',
              hoverlabel: { namelength: -1 },
              showgrid: true,
            }}
            config={{
              toImageButtonOptions: {
                width: 1000,
              },
            }}
            useResizeHandler
          />
        )}
      </div>
    </div>
  );
}

ChartContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  chartData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        x: PropTypes.arrayOf(PropTypes.number),
        y: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
  showDynamic: PropTypes.bool.isRequired,
  toggleDynamic: PropTypes.func.isRequired,
};

export default ChartContainer;
