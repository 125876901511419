import React from 'react';
import { Col, Row } from 'antd';

const NoData = () => (
  <div className="no-data-container">
    <Row className="no-data" type="flex" justify="space-around" align="middle">
      <Col span={4}>
        <p>Brak danych dla wybranej kombinacji filtrów</p>
      </Col>
    </Row>
  </div>
);

export default NoData;
