import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { Button } from 'antd';
import ChartContainer from '../ChartContainer';
import TableContainer from '../TableContainer';
import FilterPanel from './city_comparison/CityComparisonFilterPanel';
import {
  FETCH_AREA_VALUE,
  FETCH_CITY_COMPARISON_DATA,
  FETCH_CITY_VALUE,
  FETCH_COMPARISON_GROUP_VALUE,
  FETCH_INDICATOR_OPTIONS,
  FETCH_INDICATOR_VALUE,
  FETCH_SUBAREA_OPTIONS,
  FETCH_SUBAREA_VALUE,
  UPDATE_CITY_COMPARISON_TABLE_COLUMNS,
  UPDATE_CITY_COMPARISON_TABLE_ROWS,
  UPDATE_CITY_COMPARISON_STRUCTURE,
  TOGGLE_CITY_COMPARISON_DYNAMIC,
} from '../../redux/actions/actionTypes';
import { makeAction } from '../../redux/actions/makeAction';
import {
  getChartData,
  getTableData,
  isCityComparisonLoading,
  functionColumnsSettings,
  functionRowsSettings,
  structureSetting,
  dynamicSetting,
  getCityComparisonReportURL,
  getCityComparisonExportURL,
} from '../../redux/selectors/cityComparison';
import { getCityId, getComparisonGroupIds, getIndicatorId } from '../../redux/selectors/filters';
import DescriptionTooltip from './city_comparison/DescriptionTooltip';

function renderInfoContainer(title, description) {
  if (title) {
    return (
      <div className="info-container">
        <h2>
          <span>Wyniki dla:</span>
          <DescriptionTooltip description={description}>
            <span className="title">{title}</span>
          </DescriptionTooltip>
        </h2>
      </div>
    );
  }
  return null;
}

function CityComparisonPage(props) {
  const {
    fetchCityComparisonData,
    tableData,
    chartData,
    functionColumns,
    functionRows,
    updateTableColumns,
    updateTableRows,
    cityId,
    indicatorId,
    comparisonGroupIds,
    isLoading,
    showStructure,
    updateStructure,
    showDynamic,
    toggleDynamic,
    reportURL,
  } = props;

  function populateFilters(queryString) {
    const {
      fetchCityValue,
      fetchAreaValue,
      fetchSubAreaValue,
      fetchIndicatorValue,
      fetchComparisonGroupValue,
      fetchSubAreaOptions,
      fetchIndicatorOptions,
    } = props;
    const {
      city,
      area,
      subarea,
      indicator,
      comparison_group: comparisonGroup,
    } = parse(queryString, { ignoreQueryPrefix: true });
    if (city) {
      fetchCityValue(city);
    }
    if (area) {
      fetchAreaValue(area);
      fetchSubAreaOptions(area);

      if (subarea) {
        fetchSubAreaValue(subarea);
        fetchIndicatorOptions(subarea);

        if (indicator) {
          fetchIndicatorValue(indicator);
        }
      }
    }
    if (comparisonGroup) {
      fetchComparisonGroupValue(comparisonGroup);
    }

    fetchCityComparisonData({ indicator, comparison_group: comparisonGroup, reference: city });
  }

  const location = useLocation();
  useEffect(() => populateFilters(location.search), [location.search]);
  useEffect(() => {
    if (cityId && indicatorId && comparisonGroupIds) {
      fetchCityComparisonData({
        indicator: indicatorId,
        comparison_group: comparisonGroupIds,
        reference: cityId,
      });
    }
  }, [cityId, location.pathname]);

  const exportURL = useSelector(getCityComparisonExportURL);

  return (
    <>
      <FilterPanel />
      <div className="container">
        {renderInfoContainer(tableData.title, tableData.description)}
        <TableContainer
          tableData={tableData}
          functionColumns={functionColumns}
          functionRows={functionRows}
          updateTableColumns={updateTableColumns}
          updateTableRows={updateTableRows}
          showStructure={showStructure}
          updateStructure={updateStructure}
          hasStructure
          exportURL={exportURL}
        />
        <ChartContainer
          chartData={chartData}
          isLoading={isLoading}
          showDynamic={showDynamic}
          toggleDynamic={toggleDynamic}
        />
        {isLoading || (
          <div className="report-button-container">
            <Button href={reportURL}>Pobierz PDF</Button>
          </div>
        )}
      </div>
    </>
  );
}

CityComparisonPage.defaultProps = {
  cityId: undefined,
  indicatorId: undefined,
  comparisonGroupIds: [],
};

CityComparisonPage.propTypes = {
  fetchCityComparisonData: PropTypes.func.isRequired,
  fetchSubAreaOptions: PropTypes.func.isRequired,
  fetchIndicatorOptions: PropTypes.func.isRequired,
  fetchCityValue: PropTypes.func.isRequired,
  fetchAreaValue: PropTypes.func.isRequired,
  fetchSubAreaValue: PropTypes.func.isRequired,
  fetchIndicatorValue: PropTypes.func.isRequired,
  fetchComparisonGroupValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  chartData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        x: PropTypes.arrayOf(PropTypes.number),
        y: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  cityId: PropTypes.number,
  indicatorId: PropTypes.number,
  comparisonGroupIds: PropTypes.arrayOf(PropTypes.number),
  functionColumns: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    average: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableColumns: PropTypes.func.isRequired,
  functionRows: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    average: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableRows: PropTypes.func.isRequired,
  showStructure: PropTypes.bool.isRequired,
  updateStructure: PropTypes.func.isRequired,
  showDynamic: PropTypes.bool.isRequired,
  toggleDynamic: PropTypes.func.isRequired,
  reportURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: isCityComparisonLoading(state),
  chartData: getChartData(state),
  tableData: getTableData(state),
  cityId: getCityId(state),
  indicatorId: getIndicatorId(state),
  comparisonGroupIds: getComparisonGroupIds(state),
  functionColumns: functionColumnsSettings(state),
  functionRows: functionRowsSettings(state),
  showStructure: structureSetting(state),
  showDynamic: dynamicSetting(state),
  reportURL: getCityComparisonReportURL(state),
});

const mapDispatchToProps = {
  fetchCityComparisonData: makeAction(FETCH_CITY_COMPARISON_DATA),
  fetchSubAreaOptions: makeAction(FETCH_SUBAREA_OPTIONS),
  fetchIndicatorOptions: makeAction(FETCH_INDICATOR_OPTIONS),
  fetchCityValue: makeAction(FETCH_CITY_VALUE),
  fetchAreaValue: makeAction(FETCH_AREA_VALUE),
  fetchSubAreaValue: makeAction(FETCH_SUBAREA_VALUE),
  fetchIndicatorValue: makeAction(FETCH_INDICATOR_VALUE),
  fetchComparisonGroupValue: makeAction(FETCH_COMPARISON_GROUP_VALUE),
  updateTableColumns: makeAction(UPDATE_CITY_COMPARISON_TABLE_COLUMNS),
  updateTableRows: makeAction(UPDATE_CITY_COMPARISON_TABLE_ROWS),
  updateStructure: makeAction(UPDATE_CITY_COMPARISON_STRUCTURE),
  toggleDynamic: makeAction(TOGGLE_CITY_COMPARISON_DYNAMIC),
};

export default connect(mapStateToProps, mapDispatchToProps)(CityComparisonPage);
