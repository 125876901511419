import React from 'react';
import { Skeleton } from 'antd';
import _ from 'lodash';

export const formatInteger = (amount) => {
  let roundedValue = _.round(amount);
  if (Object.is(roundedValue, -0)) {
    roundedValue = 0;
  }
  return Intl.NumberFormat('fr', { maximumFractionDigits: 0 }).format(roundedValue);
};
export const formatFloat = (amount, decimalPlaces = 2) => {
  let roundedValue = _.round(amount, decimalPlaces);
  if (Object.is(roundedValue, -0)) {
    roundedValue = 0;
  }
  const formattedValue = Intl.NumberFormat('fr', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(roundedValue);

  return formattedValue === '-0' ? '0' : formattedValue;
};
export const formatPercent = (amount) =>
  _.isFinite(amount) ? `${formatFloat(amount)}%` : <span className="no-data-cell">B/D</span>;

function customFormat(amount, decimalPlaces = 2) {
  if (decimalPlaces) {
    return formatFloat(amount, decimalPlaces);
  }
  return formatInteger(amount);
}

export const formatFunctional = (amount) =>
  _.isFinite(amount) ? (
    customFormat(amount, !_.isSafeInteger(amount))
  ) : (
    <span className="no-data-cell">B/D</span>
  );

export function formatTableValue(amount, record) {
  if (!_.isFinite(amount)) {
    return <span className="no-data-cell">B/D</span>;
  }
  if (record.key === 'sum' || record.key === 'mean') {
    return formatFunctional(amount);
  }
  if (record.isStructure) {
    return formatPercent(amount);
  }
  return customFormat(amount, record.precision);
}

export const renderSkeleton = (value) =>
  value || <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />;

export const sortByLabel = (items) => {
  items.sort((a, b) =>
    a.label.localeCompare(b.label, 'pl', {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};

export const sortByName = (items) => {
  items.sort((a, b) =>
    a.name.localeCompare(b.name, 'pl', {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};
