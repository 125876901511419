import { createSelector } from 'reselect';
import _ from 'lodash';

export const filtersBranch = (state) => state.filters;

export const getCityId = createSelector(
  filtersBranch,
  (branch) => branch.cityFilter.value && branch.cityFilter.value.key,
);

export const getAreaId = createSelector(
  filtersBranch,
  (branch) => branch.areaFilter.value && branch.areaFilter.value.key,
);

export const getSubAreaId = createSelector(
  filtersBranch,
  (branch) => branch.subAreaFilter.value && branch.subAreaFilter.value.key,
);

export const getIndicatorId = createSelector(
  filtersBranch,
  (branch) => branch.indicatorFilter.value && branch.indicatorFilter.value.key,
);

export const getComparisonGroupIds = createSelector(filtersBranch, (branch) =>
  _.reduce(
    branch.comparisonGroupFilter.value,
    (result, value) => {
      result.push(value.key);
      return result;
    },
    [],
  ),
);

export const isCityComparisonFilterComplete = createSelector(
  filtersBranch,
  ({ cityFilter, indicatorFilter, isChanged }) =>
    !_.isUndefined(cityFilter.value) && !_.isUndefined(indicatorFilter.value) && isChanged,
);

export const isDashboardFilterComplete = createSelector(
  filtersBranch,
  ({ cityFilter, areaFilter, subAreaFilter, isChanged }) =>
    !_.isUndefined(cityFilter.value) &&
    !_.isUndefined(areaFilter.value) &&
    !_.isUndefined(subAreaFilter.value) &&
    isChanged,
);

export const getFilter = (state, props) => state.filters[props.filterName];

export const getFilterValue = createSelector(getFilter, (filter) => filter.value);

export const getFilterOptions = createSelector(getFilter, (filter) => filter.options);
