import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { CITY_COMPARISON_PAGE, DASHBOARD_PAGE, LANDING_PAGE } from './paths';

import DashboardPage from '../components/pages/DashboardPage';
import LandingPage from '../components/pages/LandingPage';
import CityComparisonPage from '../components/pages/CityComparisonPage';

const Router = () => (
  <Switch>
    <Route exact path={LANDING_PAGE} component={LandingPage} />
    <Route exact path={DASHBOARD_PAGE} component={DashboardPage} />
    <Route exact path={CITY_COMPARISON_PAGE} component={CityComparisonPage} />
    <Redirect to={LANDING_PAGE} />
  </Switch>
);

export default Router;
