import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import NoData from './NoData';
import TableSettings from './TableSettings';

export default function TableContainer({
  tableData,
  functionColumns,
  functionRows,
  updateTableColumns,
  updateTableRows,
  showStructure,
  updateStructure,
  hasStructure,
  exportURL,
}) {
  return (
    <div className="table-container">
      {tableData.title && (
        <TableSettings
          functionColumns={functionColumns}
          functionRows={functionRows}
          updateTableColumns={updateTableColumns}
          updateTableRows={updateTableRows}
          showStructure={showStructure}
          updateStructure={updateStructure}
          hasStructure={hasStructure}
          exportURL={exportURL}
        />
      )}
      <Table
        id="table"
        locale={{ emptyText: <NoData /> }}
        columns={tableData.columns}
        dataSource={tableData.data}
        pagination={false}
        rowClassName={(record) => {
          if (record.reference) {
            return 'reference';
          }
          if (record.key === 'sum' || record.key === 'mean') {
            return 'function-row';
          }
          return '';
        }}
        scroll={{ x: 1600 }}
      />
    </div>
  );
}

TableContainer.defaultProps = {
  hasStructure: false,
};

TableContainer.propTypes = {
  tableData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  }).isRequired,
  functionColumns: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    average: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableColumns: PropTypes.func.isRequired,
  functionRows: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    mean: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableRows: PropTypes.func.isRequired,
  showStructure: PropTypes.bool.isRequired,
  updateStructure: PropTypes.func.isRequired,
  hasStructure: PropTypes.bool,
  exportURL: PropTypes.string.isRequired,
};
