import React, { useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { makeAction } from '../../../redux/actions/makeAction';
import {
  FETCH_AREA_OPTIONS,
  FETCH_CITY_OPTIONS,
  FETCH_SUBAREA_OPTIONS,
  UPDATE_AREA_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_SUBAREA_FILTER,
} from '../../../redux/actions/actionTypes';
import {
  getAreaId,
  getCityId,
  getSubAreaId,
  isDashboardFilterComplete,
} from '../../../redux/selectors/filters';
import { getDashboardURL } from '../../../redux/selectors/dashboard';
import { LANDING_PAGE } from '../../../configuration/paths';
import FilterSelect from '../../FilterSelect';
import SelectCity from '../city_comparison/SelectCity';

function DashboardFilterPanel({
  fetchAreaOptions,
  fetchSubAreaOptions,
  areaId,
  updateAreaFilter,
  updateCityFilter,
  updateSubAreaFilter,
  buttonDisabled,
  dashboardURL,
}) {
  const location = useLocation();
  useEffect(() => {
    fetchAreaOptions();
    if (areaId) {
      fetchSubAreaOptions(areaId);
    }
  }, [location]);

  function handleAreaChange(area) {
    updateAreaFilter(area);
    fetchSubAreaOptions(area.key);
  }

  const isLanding = location.pathname === LANDING_PAGE;
  return (
    <div className="filter-panel">
      <div className="container">
        <div className="filter-wrapper">
          {isLanding ? (
            <h2>Konfiguracja</h2>
          ) : (
            <Row gutter={[20, 0]}>
              <Col span={10}>
                <span>Obszar</span>
              </Col>
              <Col span={10}>
                <span>Podobszar</span>
              </Col>
            </Row>
          )}
          <Row gutter={[20, 0]}>
            {isLanding && (
              <Col span={6}>
                <SelectCity
                  filterName="cityFilter"
                  selectProps={{
                    placeholder: 'Szukaj JST lub partnerstwa',
                    onChange: updateCityFilter,
                    labelInValue: true,
                    showArrow: false,
                    showSearch: true,
                  }}
                  cityFilter
                />
              </Col>
            )}
            <Col span={isLanding ? 6 : 10}>
              <FilterSelect
                filterName="areaFilter"
                selectProps={{ placeholder: 'Obszar', onChange: handleAreaChange }}
              />
            </Col>
            <Col span={isLanding ? 6 : 10}>
              <FilterSelect
                filterName="subAreaFilter"
                selectProps={{
                  placeholder: 'Podobszar',
                  onChange: updateSubAreaFilter,
                  disabled: !areaId,
                }}
              />
            </Col>
            <Col span={isLanding ? 6 : 4}>
              <Link to={dashboardURL}>
                <Button type="primary" disabled={buttonDisabled} block>
                  {isLanding ? (
                    <>
                      {'Pokaż wskaźniki '}
                      <RightOutlined />
                    </>
                  ) : (
                    <>
                      {'Zastosuj '}
                      <RightOutlined />
                    </>
                  )}
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

DashboardFilterPanel.defaultProps = {
  areaId: undefined,
};

DashboardFilterPanel.propTypes = {
  updateCityFilter: PropTypes.func.isRequired,
  updateAreaFilter: PropTypes.func.isRequired,
  updateSubAreaFilter: PropTypes.func.isRequired,
  areaId: PropTypes.number,
  fetchAreaOptions: PropTypes.func.isRequired,
  fetchSubAreaOptions: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  dashboardURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cityId: getCityId(state),
  areaId: getAreaId(state),
  subAreaId: getSubAreaId(state),
  buttonDisabled: !isDashboardFilterComplete(state),
  dashboardURL: getDashboardURL(state),
});

const mapDispatchToProps = {
  updateCityFilter: makeAction(UPDATE_CITY_FILTER),
  updateAreaFilter: makeAction(UPDATE_AREA_FILTER),
  updateSubAreaFilter: makeAction(UPDATE_SUBAREA_FILTER),
  fetchCityOptions: makeAction(FETCH_CITY_OPTIONS),
  fetchAreaOptions: makeAction(FETCH_AREA_OPTIONS),
  fetchSubAreaOptions: makeAction(FETCH_SUBAREA_OPTIONS),
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilterPanel);
