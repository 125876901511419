import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import dashboard from './dashboardReducer';
import filters from './filtersReducer';
import cityComparison from './cityComparisonReducer';

const rootReducer = (history) =>
  combineReducers({
    filters,
    dashboard,
    cityComparison,
    router: connectRouter(history),
  });

export default rootReducer;
