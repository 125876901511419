import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { getFilterOptions, getFilterValue } from '../redux/selectors/filters';
import { sortByLabel } from '../services/format';

const { Option, OptGroup } = Select;

function renderFilterOptions(options, filterName) {
  if (filterName === 'cityFilter') {
    const cities = options.filter((option) => !option.isGroup);
    const groups = options.filter((option) => option.isGroup);
    return [
      <OptGroup key="Miasta">
        {cities.map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>,
      <OptGroup key="Grupy">
        {groups.map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>,
    ];
  }
  return sortByLabel(options).map(({ key, label }) => (
    <Option key={key} value={key} label={label}>
      {label}
    </Option>
  ));
}

const FilterSelect = ({ filterValue, filterOptions, selectProps, filterName }) => (
  <Select
    showSearch
    dropdownMatchSelectWidth={false}
    labelInValue
    value={filterValue}
    showArrow={false}
    defaultActiveFirstOption={false}
    optionFilterProp="children"
    filterOption={(input, option) => {
      if (option && option.label) {
        return option.label.toLowerCase().startsWith(input.toLowerCase());
      }
      return false;
    }}
    notFoundContent={null}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...selectProps}
  >
    {renderFilterOptions(filterOptions, filterName)}
  </Select>
);

FilterSelect.defaultProps = {
  selectProps: {},
  filterValue: undefined,
};

FilterSelect.propTypes = {
  filterValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filterOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object,
  filterName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  filterValue: getFilterValue(state, props),
  filterOptions: getFilterOptions(state, props),
});

export default connect(mapStateToProps)(FilterSelect);
