import {
  FETCH_CITY_COMPARISON_DATA,
  UPDATE_CITY_COMPARISON_TABLE_COLUMNS,
  UPDATE_CITY_COMPARISON_TABLE_ROWS,
  UPDATE_CITY_COMPARISON_STRUCTURE,
  TOGGLE_CITY_COMPARISON_ROW_SELECT,
  TOGGLE_CITY_COMPARISON_DYNAMIC,
} from '../actions/actionTypes';
import { failed, success } from '../actions/makeAction';

const DEFAULT_STATE = {
  indicator: {},
  cities: [],
  aggregates: [],
  settings: {
    functionColumns: {
      difference: true,
      dynamic: true,
      mean: false,
      sum: false,
    },
    functionRows: {
      mean: false,
      sum: false,
    },
    showStructure: false,
    showDynamic: false,
  },
  isLoading: true,
};

const cityComparison = (state, { type, payload }) => {
  if (!state) {
    return DEFAULT_STATE;
  }
  switch (type) {
    case FETCH_CITY_COMPARISON_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case success(FETCH_CITY_COMPARISON_DATA):
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case failed(FETCH_CITY_COMPARISON_DATA):
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CITY_COMPARISON_TABLE_COLUMNS:
      return {
        ...state,
        settings: {
          ...state.settings,
          functionColumns: {
            ...state.settings.functionColumns,
            [payload.column]: payload.value,
          },
        },
      };
    case UPDATE_CITY_COMPARISON_TABLE_ROWS:
      return {
        ...state,
        settings: {
          ...state.settings,
          functionRows: {
            ...state.settings.functionRows,
            [payload.row]: payload.value,
          },
        },
      };
    case UPDATE_CITY_COMPARISON_STRUCTURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          showStructure: payload,
        },
      };
    case TOGGLE_CITY_COMPARISON_ROW_SELECT:
      return {
        ...state,
        cities: [
          ...state.cities.map((city, index) => {
            if (index === payload) {
              return {
                ...city,
                isSelected: !city.isSelected,
              };
            }
            return city;
          }),
        ],
      };
    case TOGGLE_CITY_COMPARISON_DYNAMIC:
      return {
        ...state,
        settings: {
          ...state.settings,
          showDynamic: payload,
        },
      };
    default:
      return state;
  }
};

export default cityComparison;
