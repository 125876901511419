/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { API_URL } from '../../../configuration/paths';
import { showErrorMessage } from '../../../services/notifications';

export function AboutAnalizy() {
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    async function fetchAttachments() {
      try {
        const response = await axios(`${API_URL}/attachment/`);
        setAttachments(response.data);
      } catch (e) {
        showErrorMessage('Błąd przy pobieraniu załączników');
      }
    }
    fetchAttachments();
  }, []);

  return (
    <>
      <p className="paragraph">
        Narzędzie do analiz pogłębionych, będące częścią Monitora Rozwoju Lokalnego zostało
        przygotowane przez Związek Miast Polskich w ramach projektu predefiniowanego pn.{' '}
        <strong>
          &quot;Budowanie potencjału instytucjonalnego średnich i małych miast w Polsce na rzecz
          wdrażania skutecznych lokalnych polityk rozwoju&quot;
        </strong>
        . Realizowany projekt stanowi wsparcie działań Ministerstwa Funduszy i Polityki Regionalnej
        w ramach wdrażanego Programu &quot;Rozwój lokalny&quot;, współfinansowanego ze środków
        Mechanizmu Finansowego EOG i Norweskiego Mechanizmu Finansowego 2014-2021.
      </p>

      <p className="paragraph">
        Oprogramowanie umożliwia przeprowadzenie pogłębionych analiz w zakresie potencjału
        społeczno-ekonomicznego gminy. Monitor pozwala na analizę danych w zadanym okresie zarówno
        wyłącznie w odniesieniu do wybranej gminy, jak również w relacji do określonej grupy
        porównawczej. Stanowi zatem uzupełnienie Monitora Rozwoju Lokalnego (
        <a href="https://gminy.monitorrozwoju.pl/">https://gminy.monitorrozwoju.pl/</a>
        oraz{' '}
        <a href="https://partnerstwa.monitorrozwoju.pl/">https://partnerstwa.monitorrozwoju.pl/</a>)
        a także istotne źródło wiedzy o wymiarze ilościowym zjawisk społeczno-gospodarczych,
        niezbędnych do przeprowadzenia pogłębionej diagnozy stanu gminy oraz identyfikacji mocnych i
        słabych stron oraz szans i zagrożeń.
      </p>

      <p className="paragraph">
        Źródłem danych są zbiory udostępnione przez instytucje publiczne, w tym m.in. przez Główny
        Urząd Statystyczny oraz Ministerstwo Finansów (sprawozdania finansowe RB). Narzędzie do
        analiz pogłębionych obejmuje łącznie 780 wskaźników, zgrupowanych w 12 obszarach. Narzędzie
        pozwala na pogłębioną analizę aktywności ekonomicznej mieszkańców, jakości edukacji,
        procesów demograficznych, rynku pracy, czy też struktury migracji. Dane obejmują wszystkie
        jednostki samorządu terytorialnego.
      </p>

      <p className="paragraph">
        Narzędzie będzie można wykorzystywać, m.in. na potrzeby opracowywania Raportu o stanie gminy
        oraz do weryfikacji wieloletnich prognoz finansowych.
      </p>

      {!_.isEmpty(attachments) && (
        <div className="attachments-container">
          <h2>Załączniki</h2>
          <ul>
            {attachments.map(({ name, url }) => (
              <li>
                <a href={url}>{name}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export function AboutEnterpreneurship() {
  return (
    <>
      <p className="paragraph">
        Portal MRL przedsiębiorczość będący częścią Monitora Rozwoju Lokalnego
        (www.monitorrozwoju.pl) został przygotowany przez Związek Miast Polskich w ramach projektu
        predefiniowanego pn.{' '}
        <strong>
          &quot;Budowanie potencjału instytucjonalnego średnich i małych miast w Polsce na rzecz
          wdrażania skutecznych lokalnych polityk rozwoju&quot;
        </strong>
        . Realizowany projekt stanowi wsparcie działań Ministerstwa Funduszy i Polityki Regionalnej
        w ramach wdrażanego Programu &quot;Rozwój lokalny&quot;, współfinansowanego ze środków
        Mechanizmu Finansowego EOG i Norweskiego Mechanizmu Finansowego 2014-2021.
      </p>

      <p className="paragraph">
        Oprogramowanie umożliwia analizę stanu przedsiębiorczości lokalnej, atrakcyjności
        inwestycyjnej oraz konkurencyjności lokalnej oraz rynku pracy. Pozwala na analizę danych w
        zadanym okresie zarówno wyłącznie w odniesieniu do wybranej gminy, jak również w relacji do
        określonej grupy porównawczej. Stanowi zatem uzupełnienie Monitora Rozwoju Lokalnego (
        <a href="https://gminy.monitorrozwoju.pl/">https://gminy.monitorrozwoju.pl/</a>,{' '}
        <a href="https://analizy.monitorrozwoju.pl/">https://analizy.monitorrozwoju.pl/</a> oraz{' '}
        <a href="https://partnerstwa.monitorrozwoju.pl/">https://partnerstwa.monitorrozwoju.pl/</a>)
        a także istotne źródło wiedzy o wymiarze ilościowym zjawisk społeczno-gospodarczych,
        niezbędnych do przeprowadzenia pogłębionej diagnozy stanu gminy oraz identyfikacji mocnych i
        słabych stron oraz szans i zagrożeń.
      </p>

      <p className="paragraph">
        Źródłem danych są zbiory udostępnione Ministerstwo Finansów i zasoby POLTAX dane z ZUS oraz
        z KRUS; wzbogacone danymi demograficznymi pochodzącymi Głównego Urzędu Statystycznego. W
        portalu znajduje się wskaźniki pozwalające na pogłębioną analizę aktywności ekonomicznej
        mieszkańców, procesów demograficznych, rynku pracy, czy też struktury migracji. Dane
        obejmują wszystkie jednostki samorządu terytorialnego.
      </p>
    </>
  );
}
