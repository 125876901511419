import React from 'react';
import { AboutAnalizy, AboutEnterpreneurship } from './aboutUsOptions';

function AboutUs() {
  return (
    <div className="about-container">
      {window.location.hostname.includes('analizy.') ? <AboutAnalizy /> : <AboutEnterpreneurship />}
    </div>
  );
}

export default AboutUs;
