export const UPDATE_CITY_FILTER = 'UPDATE_CITY_FILTER';
export const UPDATE_AREA_FILTER = 'UPDATE_AREA_FILTER';
export const UPDATE_SUBAREA_FILTER = 'UPDATE_SUBAREA_FILTER';
export const UPDATE_INDICATOR_FILTER = 'UPDATE_INDICATOR_FILTER';
export const UPDATE_COMPARISON_GROUP_FILTER = 'UPDATE_COMPARISON_GROUP_FILTER';

export const FETCH_AREA_OPTIONS = 'FETCH_AREA_OPTIONS';
export const FETCH_AREA_VALUE = 'FETCH_AREA_VALUE';

export const FETCH_CITY_OPTIONS = 'FETCH_CITY_OPTIONS';
export const FETCH_CITY_VALUE = 'FETCH_CITY_VALUE';

export const FETCH_INDICATOR_OPTIONS = 'FETCH_INDICATOR_OPTIONS';
export const FETCH_INDICATOR_VALUE = 'FETCH_INDICATOR_VALUE';

export const FETCH_SUBAREA_OPTIONS = 'FETCH_SUBAREA_OPTIONS';
export const FETCH_SUBAREA_VALUE = 'FETCH_SUBAREA_VALUE';

export const FETCH_CITY_COMPARISON_DATA = 'FETCH_CITY_COMPARISON_DATA';
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';

export const FETCH_COMPARISON_GROUP_VALUE = 'FETCH_COMPARISON_GROUP_VALUE';

export const UPDATE_DASHBOARD_TABLE_COLUMNS = 'UPDATE_DASHBOARD_TABLE_COLUMNS';
export const UPDATE_DASHBOARD_TABLE_ROWS = 'UPDATE_DASHBOARD_TABLE_ROWS';

export const UPDATE_CITY_COMPARISON_TABLE_COLUMNS = 'UPDATE_CITY_COMPARISON_TABLE_COLUMNS';
export const UPDATE_CITY_COMPARISON_TABLE_ROWS = 'UPDATE_CITY_COMPARISON_TABLE_ROWS';

export const UPDATE_DASHBOARD_STRUCTURE = 'UPDATE_DASHBOARD_STRUCTURE';
export const UPDATE_CITY_COMPARISON_STRUCTURE = 'UPDATE_CITY_COMPARISON_STRUCTURE';

export const TOGGLE_DASHBOARD_ROW_SELECT = 'TOGGLE_DASHBOARD_ROW_SELECT';
export const TOGGLE_CITY_COMPARISON_ROW_SELECT = 'TOGGLE_CITY_COMPARISON_ROW_SELECT';

export const TOGGLE_DASHBOARD_DYNAMIC = 'TOGGLE_DASHBOARD_DYNAMIC';
export const TOGGLE_CITY_COMPARISON_DYNAMIC = 'TOGGLE_CITY_COMPARISON_DYNAMIC';
