import React from 'react';
// import { FilePdfOutlined } from '@ant-design/icons';
import Jumbotron from './landing/Jumbotron';
import FilterPanel from './dashboard/DashboardFilterPanel';
import AboutUs from './landing/AboutUs';

function LandingPage() {
  return (
    <>
      <Jumbotron />
      <FilterPanel />
      {/* <h2 className="manual"> */}
      {/*  <a href="/Monitor_ instrukcja.pdf"> */}
      {/*    <FilePdfOutlined /> */}
      {/*    {' Instrukcja obsługi narzędzia'} */}
      {/*  </a> */}
      {/* </h2> */}
      <AboutUs />
    </>
  );
}

export default LandingPage;
