import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ChartContainer from '../ChartContainer';
import TableContainer from '../TableContainer';
import FilterPanel from './dashboard/DashboardFilterPanel';
import {
  FETCH_AREA_VALUE,
  FETCH_CITY_VALUE,
  FETCH_DASHBOARD_DATA,
  FETCH_SUBAREA_OPTIONS,
  FETCH_SUBAREA_VALUE,
  UPDATE_DASHBOARD_TABLE_COLUMNS,
  UPDATE_DASHBOARD_TABLE_ROWS,
  UPDATE_DASHBOARD_STRUCTURE,
  TOGGLE_DASHBOARD_DYNAMIC,
} from '../../redux/actions/actionTypes';
import { makeAction } from '../../redux/actions/makeAction';
import {
  functionColumnsSettings,
  functionRowsSettings,
  getChartData,
  getTableData,
  isDashboardLoading,
  structureSetting,
  isDataStructured,
  dynamicSetting,
  getDashboardReportURL,
  getDashboardExportURL,
} from '../../redux/selectors/dashboard';
import { getCityId, getSubAreaId } from '../../redux/selectors/filters';

function DashboardPage(props) {
  const {
    tableData,
    chartData,
    functionColumns,
    functionRows,
    updateTableColumns,
    updateTableRows,
    isLoading,
    fetchDashboardData,
    cityId,
    subAreaId,
    showStructure,
    updateStructure,
    hasStructure,
    showDynamic,
    toggleDynamic,
    reportURL,
  } = props;

  function populateFilters(queryString) {
    const { fetchCityValue, fetchAreaValue, fetchSubAreaOptions, fetchSubAreaValue } = props;
    const { city, area, subarea } = parse(queryString, { ignoreQueryPrefix: true });
    if (city && area && subarea) {
      fetchCityValue(city);
      fetchAreaValue(area);
      fetchSubAreaOptions(area);
      fetchSubAreaValue(subarea);
    }
    fetchDashboardData({ gmina: city, subarea });
  }

  const location = useLocation();
  useEffect(() => populateFilters(location.search), [location.search]);
  useEffect(() => {
    if (cityId && subAreaId) {
      fetchDashboardData({ gmina: cityId, subarea: subAreaId });
    }
  }, [cityId, location.pathname]);

  const exportURL = useSelector(getDashboardExportURL);

  return (
    <>
      <FilterPanel />
      <div className="container">
        {tableData.title && (
          <div className="info-container">
            <h2>
              <span>Wyniki dla:</span>
              <span className="title">{tableData.title}</span>
              {tableData.comment && (
                <Tooltip title={tableData.comment} placement="top">
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </h2>
          </div>
        )}
        <TableContainer
          tableData={tableData}
          functionColumns={functionColumns}
          functionRows={functionRows}
          updateTableColumns={updateTableColumns}
          updateTableRows={updateTableRows}
          showStructure={showStructure}
          updateStructure={updateStructure}
          hasStructure={hasStructure}
          exportURL={exportURL}
        />
        <ChartContainer
          chartData={chartData}
          isLoading={isLoading}
          showDynamic={showDynamic}
          toggleDynamic={toggleDynamic}
        />
        {isLoading || (
          <div className="report-button-container">
            <Button href={reportURL}>Pobierz PDF</Button>
          </div>
        )}
      </div>
    </>
  );
}

DashboardPage.defaultProps = {
  cityId: undefined,
  subAreaId: undefined,
};

DashboardPage.propTypes = {
  fetchDashboardData: PropTypes.func.isRequired,
  fetchSubAreaOptions: PropTypes.func.isRequired,
  fetchCityValue: PropTypes.func.isRequired,
  fetchAreaValue: PropTypes.func.isRequired,
  fetchSubAreaValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  chartData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        x: PropTypes.arrayOf(PropTypes.number),
        y: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
  cityId: PropTypes.number,
  subAreaId: PropTypes.number,
  functionColumns: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    average: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableColumns: PropTypes.func.isRequired,
  functionRows: PropTypes.shape({
    difference: PropTypes.bool,
    dynamic: PropTypes.bool,
    average: PropTypes.bool,
    sum: PropTypes.bool,
  }).isRequired,
  updateTableRows: PropTypes.func.isRequired,
  showStructure: PropTypes.bool.isRequired,
  updateStructure: PropTypes.func.isRequired,
  hasStructure: PropTypes.bool.isRequired,
  showDynamic: PropTypes.bool.isRequired,
  toggleDynamic: PropTypes.func.isRequired,
  reportURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: isDashboardLoading(state),
  chartData: getChartData(state),
  tableData: getTableData(state),
  cityId: getCityId(state),
  subAreaId: getSubAreaId(state),
  functionColumns: functionColumnsSettings(state),
  functionRows: functionRowsSettings(state),
  showStructure: structureSetting(state),
  hasStructure: isDataStructured(state),
  showDynamic: dynamicSetting(state),
  reportURL: getDashboardReportURL(state),
});

const mapDispatchToProps = {
  fetchDashboardData: makeAction(FETCH_DASHBOARD_DATA),
  fetchSubAreaOptions: makeAction(FETCH_SUBAREA_OPTIONS),
  fetchCityValue: makeAction(FETCH_CITY_VALUE),
  fetchAreaValue: makeAction(FETCH_AREA_VALUE),
  fetchSubAreaValue: makeAction(FETCH_SUBAREA_VALUE),
  updateTableColumns: makeAction(UPDATE_DASHBOARD_TABLE_COLUMNS),
  updateTableRows: makeAction(UPDATE_DASHBOARD_TABLE_ROWS),
  updateStructure: makeAction(UPDATE_DASHBOARD_STRUCTURE),
  toggleDynamic: makeAction(TOGGLE_DASHBOARD_DYNAMIC),
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
