import React from 'react';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { formatFunctional, formatPercent } from '../../services/format';

export function getValidYears(series) {
  return _.uniq(
    _.reduce(
      series,
      (result, s) => {
        result.push(..._.flatMap(s.values, (value) => _.get(value, 'year')));
        return result;
      },
      [],
    ),
  ).sort();
}

export const chartPalette = [
  '#9b59b6',
  '#7f8c8d',
  '#27ae60',
  '#2980b9',
  '#a6acaf',
  '#e74c3c',
  '#d0d3d4',
  '#566573',
  '#1abc9c',
  '#58d68d',
  '#c0392b',
  '#3498db',
  '#7d3c98',
  '#f1c40f',
  '#138d75',
  '#f39c12',
  '#34495e',
];

export const functionColumns = {
  difference: {
    title: (
      <span>
        <Tooltip title="ostatni rok do pierwszego" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {' Różnica'}
      </span>
    ),
    dataIndex: 'difference',
    key: 'difference',
    render: formatFunctional,
    sorter: (a, b) => a.difference - b.difference,
    sortDirections: ['descend', 'ascend'],
    className: 'function-column',
  },
  dynamic: {
    title: (
      <span>
        <Tooltip title="ostatni rok do pierwszego" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {' Dynamika'}
      </span>
    ),
    dataIndex: 'dynamic',
    key: 'dynamic',
    render: formatPercent,
    sorter: (a, b) => a.dynamic - b.dynamic,
    sortDirections: ['descend', 'ascend'],
    className: 'function-column',
  },
  mean: {
    title: (
      <span>
        <Tooltip title="dla wszystkich lat" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {' Średnia'}
      </span>
    ),
    dataIndex: 'mean',
    key: 'mean',
    render: formatFunctional,
    sorter: (a, b) => a.mean - b.mean,
    sortDirections: ['descend', 'ascend'],
    className: 'function-column',
  },
  sum: {
    title: (
      <span>
        <Tooltip title="dla wszystkich lat" placement="top">
          <InfoCircleOutlined />
        </Tooltip>
        {' Suma'}
      </span>
    ),
    dataIndex: 'sum',
    key: 'sum',
    render: formatFunctional,
    sorter: (a, b) => a.sum - b.sum,
    sortDirections: ['descend', 'ascend'],
    className: 'function-column',
  },
};

export function functionRows(aggregates, { sum, mean }) {
  const rows = [];
  if (mean) {
    rows.push({
      key: 'mean',
      name: 'Średnia',
      sortable: false,
      ...aggregates.reduce(
        (result, value) => ({
          ...result,
          [value.year]: value.mean,
        }),
        {},
      ),
    });
  }
  if (sum) {
    rows.push({
      key: 'sum',
      name: 'Suma',
      sortable: false,
      ...aggregates.reduce(
        (result, value) => ({
          ...result,
          [value.year]: value.sum,
        }),
        {},
      ),
    });
  }
  return rows;
}
