import { notification } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export function showServerError() {
  const id = 'server_error';
  notification.error({
    message: 'Błąd serwera',
    description: 'Wystąpił błąd serwera, skontaktuj się z administratorem',
    key: id,
    onClick: () => {
      notification.close(id);
    },
    style: {
      cursor: 'pointer',
    },
    duration: 5,
  });
}

export function showErrorMessage(message) {
  const id = 'server_error';
  notification.error({
    message: 'Błąd',
    description: message,
    key: id,
    onClick: () => {
      notification.close(id);
    },
    style: {
      cursor: 'pointer',
    },
    duration: 5,
  });
}
