import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

function DescriptionTooltip({ description, children }) {
  return description ? (
    <Tooltip title={description} overlayClassName="info-tooltip" placement="bottomLeft">
      {children}
    </Tooltip>
  ) : (
    children
  );
}

DescriptionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
};

DescriptionTooltip.defaultProps = {
  description: undefined,
};

export default DescriptionTooltip;
