import React from 'react';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { formatTableValue, renderSkeleton, sortByName } from '../../services/format';
import { chartPalette, getValidYears, functionColumns, functionRows } from './utils';
import ChartSeriesToggle from '../../components/pages/dashboard/ChartSeriesToggle';
import { API_URL, DASHBOARD_PAGE } from '../../configuration/paths';
import { getAreaId, getCityId, getSubAreaId } from './filters';

export const dashboardBranch = (state) => state.dashboard;
export const functionColumnsSettings = (state) => state.dashboard.settings.functionColumns;
export const functionRowsSettings = (state) => state.dashboard.settings.functionRows;
export const structureSetting = (state) => state.dashboard.settings.showStructure;
export const isDataStructured = (state) => state.dashboard.hasStructure;
export const dynamicSetting = (state) => state.dashboard.settings.showDynamic;

export const isDashboardLoading = createSelector(dashboardBranch, (branch) => branch.isLoading);

export const getTableData = createSelector(
  dashboardBranch,
  functionRowsSettings,
  structureSetting,
  ({ city, indicators, aggregates, settings, isLoading }, rowSettings, structure) => {
    const yearsRange = getValidYears(indicators);

    const columns = isLoading
      ? [
          {
            title: 'Wskaźniki',
            dataIndex: 'name',
            key: 'name',
            render: renderSkeleton,
          },
        ]
      : [
          {
            dataIndex: 'chartToggle',
            key: 'chartToggle',
            width: 40,
            fixed: 'left',
          },
          {
            title: 'Wskaźniki',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 300,
          },
          ...yearsRange.map((year) => ({
            title: year,
            dataIndex: year,
            key: year,
            render: formatTableValue,
            sorter: (a, b) => {
              const aHas = typeof a[year] !== 'undefined';
              const bHas = typeof b[year] !== 'undefined';
              return aHas - bHas || (aHas === true && a[year] - b[year]) || 0;
            },
            sortDirections: ['descend', 'ascend'],
          })),
          ..._.filter(functionColumns, (_value, key) => settings.functionColumns[key]),
        ];
    const data = isLoading
      ? _.range(4).map((key) => ({ key }))
      : [
          ...sortByName(indicators).map((indicator, index) => ({
            key: index,
            chartToggle: <ChartSeriesToggle seriesKey={index} isSelected={indicator.isSelected} />,
            name: (
              <Tooltip
                title={`${indicator.name}. ${indicator.description}` || indicator.name}
                placement="bottomLeft"
                overlayClassName="table-tooltip"
              >
                <span>{indicator.name}</span>
              </Tooltip>
            ),
            sortable: true,
            isStructure: structure,
            hasDecimals: indicator.hasDecimals,
            precision: indicator.precision,
            difference: indicator.dynamic_total,
            dynamic: indicator.dynamic_total_percent,
            mean: indicator.mean,
            sum: indicator.sum,
            ...indicator.values.reduce(
              (result, value) => ({
                ...result,
                [value.year]: structure ? value.structure : value.value,
              }),
              {},
            ),
          })),
          ...functionRows(aggregates, rowSettings),
        ];

    return { columns, data, title: city.label, comment: city.comment };
  },
);

export const getChartData = createSelector(dashboardBranch, ({ indicators, city }) => {
  const data = indicators.reduce((result, indicator, index) => {
    if (indicator.isSelected) {
      result.push({
        name: indicator.name,
        x: indicator.values.map((value) => value.year),
        values: indicator.values.map((value) => value.value && value.value),
        dynamic_values: indicator.values.map(
          (value) => value.dynamic_percent && value.dynamic_percent,
        ),
        type: 'scatter',
        hoverinfo: 'y+name',
        marker: {
          color: chartPalette[index % chartPalette.length],
        },
      });
    }
    return result;
  }, []);
  return { data, title: city.label };
});

export const getDashboardURL = createSelector(
  getCityId,
  getAreaId,
  getSubAreaId,
  (cityId, areaId, subAreaId) =>
    `${DASHBOARD_PAGE}?${cityId ? `city=${cityId}` : ''}${areaId ? `&area=${areaId}` : ''}${
      subAreaId ? `&subarea=${subAreaId}` : ''
    }`,
);

export const getDashboardReportURL = createSelector(
  getCityId,
  getSubAreaId,
  dynamicSetting,
  functionColumnsSettings,
  functionRowsSettings,
  structureSetting,
  (cityId, subAreaId, showDynamic, funColumns, funRows, showStructure) =>
    `${API_URL}/dashboard/report/?${cityId ? `gmina=${cityId}` : ''}${
      subAreaId ? `&subarea=${subAreaId}` : ''
    }${showDynamic ? `&dynamic=${showDynamic}` : ''}${_.map(funColumns, (value, key) =>
      value ? `&function_columns[]=${key}` : '',
    ).join('')}${_.map(funRows, (value, key) => (value ? `&function_rows[]=${key}` : '')).join(
      '',
    )}${showStructure ? `&structure=${showStructure}` : ''}`,
);

export const getDashboardExportURL = createSelector(
  getCityId,
  getSubAreaId,
  functionColumnsSettings,
  functionRowsSettings,
  structureSetting,
  (cityId, subAreaId, funColumns, funRows, showStructure) =>
    `${API_URL}/dashboard/export/?${cityId ? `gmina=${cityId}` : ''}${
      subAreaId ? `&subarea=${subAreaId}` : ''
    }${_.map(funColumns, (value, key) => (value ? `&function_columns[]=${key}` : '')).join(
      '',
    )}${_.map(funRows, (value, key) => (value ? `&function_rows[]=${key}` : '')).join('')}${
      showStructure ? `&structure=${showStructure}` : ''
    }`,
);
