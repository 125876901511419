import React, { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';
import { getFilterValue } from '../../../redux/selectors/filters';
import { sortByLabel } from '../../../services/format';

const { Option, OptGroup } = Select;

const fetchTerritories = async (value) =>
  axios.get(`api/territory/?name=${value}`).then((res) => res.data);

const useTerritorySearch = () => {
  const [value, setValue] = useState('');

  const searchAPIDebounced = useConstant(() => AwesomeDebouncePromise(fetchTerritories, 300));

  const search = useAsync(
    async (name) => {
      if (name.length) {
        return searchAPIDebounced(name);
      }
      return [];
    },
    [value],
  );

  return {
    value,
    setValue,
    search,
  };
};

const splitTerritoriesInGroups = (territories) => {
  if (!territories) {
    return {
      partnerships: [],
      voivodeships: [],
      powiats: [],
      cities: [],
    };
  }

  const partnerships = _.remove(territories, (territory) => territory.type_name === 'partnership');
  const voivodeships = _.remove(territories, (territory) => territory.type_name === 'województwo');
  const powiats = _.remove(territories, (territory) => territory.type_name === 'powiat');

  return {
    partnerships,
    voivodeships,
    powiats,
    cities: territories,
  };
};

const SelectCity = ({ selectProps, defaultFilterValue }) => {
  const [territories, setTerritories] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { cities, partnerships, powiats, voivodeships } = useMemo(
    () => splitTerritoriesInGroups(territories),
    [territories],
  );

  const { search } = useTerritorySearch();

  const handleSearch = async (value) => {
    if (value) {
      setLoading(true);
      try {
        const response = await search.execute(value);
        setTerritories(response);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error searching for territories');
      }
      setLoading(false);
    }
  };

  const renderOptions = () => (
    <>
      <OptGroup key="Miasta">
        {cities.map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>
      <OptGroup key="Powiaty">
        {powiats.map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>
      <OptGroup key="Województwa">
        {voivodeships.map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>
      <OptGroup key="Partnerstwa">
        {sortByLabel(partnerships).map(({ key, label }) => (
          <Option key={key} value={key} label={label}>
            {label}
          </Option>
        ))}
      </OptGroup>
    </>
  );

  return (
    <Select
      loading={isLoading}
      onSearch={handleSearch}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      optionFilterProp="children"
      value={defaultFilterValue}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...selectProps}
    >
      {renderOptions()}
    </Select>
  );
};

SelectCity.defaultProps = {
  selectProps: {},
  defaultFilterValue: undefined,
};

SelectCity.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object,
  defaultFilterValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state, props) => ({
  defaultFilterValue: getFilterValue(state, props),
});

export default connect(mapStateToProps)(SelectCity);
