import {
  FETCH_DASHBOARD_DATA,
  UPDATE_DASHBOARD_TABLE_COLUMNS,
  UPDATE_DASHBOARD_TABLE_ROWS,
  UPDATE_DASHBOARD_STRUCTURE,
  TOGGLE_DASHBOARD_ROW_SELECT,
  TOGGLE_DASHBOARD_DYNAMIC,
} from '../actions/actionTypes';
import { failed, success } from '../actions/makeAction';

const DEFAULT_STATE = {
  city: {},
  indicators: [],
  aggregates: [],
  settings: {
    functionColumns: {
      difference: true,
      dynamic: false,
      mean: false,
      sum: false,
    },
    functionRows: {
      mean: false,
      sum: false,
    },
    showStructure: false,
    showDynamic: false,
  },
  hasStructure: false,
  isLoading: true,
};

const dashboard = (state, { type, payload }) => {
  if (!state) {
    return DEFAULT_STATE;
  }
  switch (type) {
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case success(FETCH_DASHBOARD_DATA):
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case failed(FETCH_DASHBOARD_DATA):
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_DASHBOARD_TABLE_COLUMNS:
      return {
        ...state,
        settings: {
          ...state.settings,
          functionColumns: {
            ...state.settings.functionColumns,
            [payload.column]: payload.value,
          },
        },
      };
    case UPDATE_DASHBOARD_TABLE_ROWS:
      return {
        ...state,
        settings: {
          ...state.settings,
          functionRows: {
            ...state.settings.functionRows,
            [payload.row]: payload.value,
          },
        },
      };
    case UPDATE_DASHBOARD_STRUCTURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          showStructure: payload,
        },
      };
    case TOGGLE_DASHBOARD_ROW_SELECT:
      return {
        ...state,
        indicators: [
          ...state.indicators.map((indicator, index) => {
            if (index === payload) {
              return {
                ...indicator,
                isSelected: !indicator.isSelected,
              };
            }
            return indicator;
          }),
        ],
      };
    case TOGGLE_DASHBOARD_DYNAMIC:
      return {
        ...state,
        settings: {
          ...state.settings,
          showDynamic: payload,
        },
      };
    default:
      return state;
  }
};

export default dashboard;
