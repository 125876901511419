import React from 'react';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { formatTableValue, renderSkeleton, sortByName } from '../../services/format';
import { chartPalette, getValidYears, functionRows, functionColumns } from './utils';
import ChartSeriesToggle from '../../components/pages/city_comparison/ChartSeriesToggle';
import { API_URL, CITY_COMPARISON_PAGE } from '../../configuration/paths';
import {
  getAreaId,
  getCityId,
  getComparisonGroupIds,
  getIndicatorId,
  getSubAreaId,
} from './filters';

export const cityComparisonBranch = (state) => state.cityComparison;
export const functionColumnsSettings = (state) => state.cityComparison.settings.functionColumns;
export const functionRowsSettings = (state) => state.cityComparison.settings.functionRows;
export const structureSetting = (state) => state.cityComparison.settings.showStructure;
export const dynamicSetting = (state) => state.cityComparison.settings.showDynamic;

export const isCityComparisonLoading = createSelector(
  cityComparisonBranch,
  (branch) => branch.isLoading,
);

export const getTableData = createSelector(
  cityComparisonBranch,
  functionRowsSettings,
  structureSetting,
  ({ cities, indicator, aggregates, settings, isLoading }, rowSettings, structure) => {
    const yearsRange = getValidYears(cities);

    const columns = isLoading
      ? [
          {
            title: 'Miasta',
            dataIndex: 'name',
            key: 'name',
            render: renderSkeleton,
          },
        ]
      : [
          {
            dataIndex: 'chartToggle',
            key: 'chartToggle',
            width: 40,
            fixed: 'left',
          },
          {
            title: 'Miasta',
            dataIndex: 'name',
            key: 'name',
            className: 'city-name',
            fixed: 'left',
            width: 150,
          },
          {
            title: 'Kod Teryt',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 150,
          },
          {
            title: 'Typ gminy',
            dataIndex: 'typeName',
            key: 'typeName',
            fixed: 'left',
            width: 150,
          },
          ...yearsRange.map((year) => ({
            title: year,
            dataIndex: year,
            key: year,
            render: formatTableValue,
            sorter: (a, b) => {
              if (a.sortable && b.sortable) {
                return a[year] - b[year];
              }
              return 0;
            },
            sortDirections: ['descend', 'ascend'],
          })),
          ..._.filter(functionColumns, (_value, key) => settings.functionColumns[key]),
        ];
    const data = isLoading
      ? _.range(4).map((key) => ({ key }))
      : [
          ...sortByName(cities).map((city, index) => ({
            key: index,
            chartToggle: <ChartSeriesToggle seriesKey={index} isSelected={city.isSelected} />,
            reference: city.reference,
            name: (
              <Tooltip
                title={city.comment || city.name}
                placement="bottomLeft"
                overlayClassName="table-tooltip"
              >
                <span>{city.name}</span>
              </Tooltip>
            ),
            typeName: city.type_name,
            code: city.code,
            sortable: true,
            isStructure: structure,
            hasDecimals: indicator.hasDecimals,
            precision: indicator.precision,
            difference: city.dynamic_total,
            dynamic: city.dynamic_total_percent,
            mean: city.mean,
            sum: city.sum,
            ...city.values.reduce(
              (result, value) => ({
                ...result,
                [value.year]: structure ? value.structure : value.value,
              }),
              {},
            ),
          })),
          ...functionRows(aggregates, rowSettings),
        ];

    return {
      columns,
      data,
      title: indicator.label,
      description: indicator.description,
    };
  },
);

export const getChartData = createSelector(cityComparisonBranch, ({ cities, indicator }) => {
  const data = cities.reduce((result, city, index) => {
    if (city.isSelected) {
      result.push({
        name: `${city.name} (${city.type_name} - ${city.code})`,
        x: city.values.map((value) => value.year),
        values: city.values.map((value) => value.value && value.value),
        dynamic_values: city.values.map((value) => value.dynamic_percent && value.dynamic_percent),
        type: 'scatter',
        hoverinfo: 'y+name',
        marker: {
          color: chartPalette[index % chartPalette.length],
        },
      });
    }
    return result;
  }, []);
  return { data, title: indicator.label };
});

export const getCityComparisonURL = createSelector(
  getCityId,
  getAreaId,
  getSubAreaId,
  getIndicatorId,
  getComparisonGroupIds,
  (cityId, areaId, subAreaId, indicatorId, comparisonGroupIds) =>
    `${CITY_COMPARISON_PAGE}?${cityId ? `city=${cityId}` : ''}${areaId ? `&area=${areaId}` : ''}${
      subAreaId ? `&subarea=${subAreaId}` : ''
    }${indicatorId ? `&indicator=${indicatorId}` : ''}${
      comparisonGroupIds ? comparisonGroupIds.map((id) => `&comparison_group[]=${id}`).join('') : ''
    }`,
);

export const getCityComparisonReportURL = createSelector(
  getCityId,
  getIndicatorId,
  getComparisonGroupIds,
  dynamicSetting,
  functionColumnsSettings,
  functionRowsSettings,
  structureSetting,
  (cityId, indicatorId, comparisonGroupIds, showDynamic, funColumns, funRows, showStructure) =>
    `${API_URL}/city_comparison/report/?${cityId ? `reference=${cityId}` : ''}${
      indicatorId ? `&indicator=${indicatorId}` : ''
    }${
      comparisonGroupIds
        ? `${comparisonGroupIds.map((id) => `&comparison_group[]=${id}`).join('')}`
        : ''
    }${showDynamic ? `&dynamic=${showDynamic}` : ''}${_.map(funColumns, (value, key) =>
      value ? `&function_columns[]=${key}` : '',
    ).join('')}${_.map(funRows, (value, key) => (value ? `&function_rows[]=${key}` : '')).join(
      '',
    )}${showStructure ? `&structure=${showStructure}` : ''}`,
);

export const getCityComparisonExportURL = createSelector(
  getCityId,
  getIndicatorId,
  getComparisonGroupIds,
  dynamicSetting,
  functionColumnsSettings,
  functionRowsSettings,
  structureSetting,
  (cityId, indicatorId, comparisonGroupIds, showDynamic, funColumns, funRows, showStructure) =>
    `${API_URL}/city_comparison/export/?${cityId ? `reference=${cityId}` : ''}${
      indicatorId ? `&indicator=${indicatorId}` : ''
    }${
      comparisonGroupIds
        ? `${comparisonGroupIds.map((id) => `&comparison_group[]=${id}`).join('')}`
        : ''
    }${showDynamic ? `&dynamic=${showDynamic}` : ''}${_.map(funColumns, (value, key) =>
      value ? `&function_columns[]=${key}` : '',
    ).join('')}${_.map(funRows, (value, key) => (value ? `&function_rows[]=${key}` : '')).join(
      '',
    )}${showStructure ? `&structure=${showStructure}` : ''}`,
);
