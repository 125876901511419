import React from 'react';
import { Col, Row } from 'antd';

const Jumbotron = () => (
  <div className="jumbotron">
    <div className="container">
      <h1 className="main-title">Program / rozwój lokalny</h1>
      <Row gutter={[20, 0]}>
        <Col span={12} offset={4} className="site-subtitle">
          <h2>Narzędzie do analiz pogłębionych</h2>
          <p className="subtitle">
            Projekt &quot;Budowanie potencjału instytucjonalnego średnich i małych miast w Polsce na
            rzecz wdrażania skutecznych lokalnych polityk rozwoju&quot;.
          </p>
        </Col>
      </Row>
    </div>
  </div>
);

export default Jumbotron;
