import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_PAGE, LANDING_PAGE } from './configuration/paths';
import Header from './components/Header';

const locations = {
  [LANDING_PAGE]: 'landing-page',
  [DASHBOARD_PAGE]: 'dashboard-page',
};

function App({ children }) {
  const { pathname } = useLocation();
  return (
    <div className={`page-wrapper ${locations[pathname]}`}>
      {pathname !== LANDING_PAGE && <Header />}
      <main>{children}</main>
    </div>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
